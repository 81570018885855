<template>
  <div>
    <b-container class="py-md-5">
      <b-row cols=" py-0 py-md-4">
        <div class="col-12 col-md-6 col-lg-6">
          <a href="#"><img src="../assets/logo.png" /></a>
          <h3 class="font-weight-bold m-0">
            Goed nieuws! Jouw woning
            <span class="text-secondary">
              {{ this.street }} {{ first_form.house_number
              }}<!----></span
            >
            in <span class="text-secondary">{{ this.city }} </span> kan tot wel
            30% subsidie ontvangen.
          </h3>
          <div class="my-2 my-md-4">
            <div>
              <GmapMap
                :options="{
                  zoomControl: false,
                  fullscreenControl: false,
                  mapTypeControl: false,
                  scaleControl: false,
                  streetViewControl: false,
                  rotateControl: true,
                  disableDefaultUi: false,
                }"
                :center="{ lng: this.lng, lat: this.lat }"
                :zoom="16"
                map-type-id="terrain"
                style="width: 100%; height: 350px"
              >
                <GmapMarker
                  :position="{
                    lng: this.lng,

                    lat: this.lat,
                  }"
                  :clickable="true"
                  :draggable="true"
                />
              </GmapMap>
            </div>
          </div>
          <h3 class="text-center bottom-text">
            Bespaar tot 30% in maar 2 minuten!
          </h3>
        </div>
        <!--  -->
        <div class="col-12 col-md-6">
          <div
            class="bg-white shadow p-3 p-md-4 banner__form text-center d-flex justify-content-center"
          >
            <h4 class="align-self-center">
              Bedankt!<br />Een van onze medewerkers neemt binnenkort contact
              met u op.
            </h4>

            <img
              v-if="profId !== null"
              referrerpolicy="no-referrer-when-downgrade"
              :src="`https://republish.verbeterhuizen.nl/m/5291/e7d5919719e6/?event=6191&unique_conversion_id=${profId}`"
              style="width: 1px; height: 1px; border: 0px"
            />
          </div>
        </div>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Bedankt2",
  computed: mapGetters([
    "lng",
    "lat",
    "first_form",
    "profId",
    "street",
    "city",
  ]),
};
</script>

<style lang="scss" scoped>
@media (max-width: 767.98px) {
  // h2 {
  //   font-size: 24px;
  // }
  .bottom-text {
    font-size: 16px;
  }
  h3 {
    font-size: 20px;
  }
}
.text-secondary {
  color: #74a301 !important;
}
.font-weight-bold {
  font-weight: 700 !important;
}

.banner {
  &__form {
    border-radius: 8px !important;
    border-left: 2px solid #eee !important;
    border-color: #74a301 !important;
    height: 590px;
  }
}
</style>
